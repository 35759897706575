import { ComponentType, Suspense, useMemo, useState } from "react";

import * as Sentry from "@sentry/react";
import { lazily } from "react-lazily";
import { graphql, useFragment } from "react-relay";
import { Routes, Navigate, Route, useParams } from "react-router";

import { AdminRoute } from "app/components/AdminRoute/AdminRoute";
import {
  DetailsSkeleton,
  RectangleSkeleton,
  TableSkeleton,
} from "app/components/skeletons";
import { useConfig } from "app/contexts";
import { useHasRequiredPermission } from "app/hooks";
import { ADMIN_PERMISSIONS } from "app/utils/consts";
import { CostDashboardBarStateProvider } from "app/views/dashboards/contexts/CostDashboardBarContext";
import { NotFound } from "app/views/NotFound";
import OnboardingContext from "app/views/onboarding/context/OnboardingContext";
import { AdminSettingsRoot } from "app/views/settings/admin/AdminSettingsRoot";

import type { Routes_accounts$key } from "__generated__/Routes_accounts.graphql";
import type { AWSOrganisationInput } from "app/mutations/__generated__/AddAWSOrganisationMutation.graphql";

// NOTE: lazy imports must not import from barrels

// onboarding
const { OnboardingWizardRoot } = lazily(
  () => import("app/views/onboarding/OnboardingWizardRoot"),
);

// overview
const { Activity } = lazily(() => import("app/views/overview"));
const { Cloud } = lazily(() => import("app/views/dashboards/cloud/Cloud"));
const { PoliciesRoot } = lazily(
  () => import("app/views/overview/policies/PoliciesRoot"),
);
const { PolicyDetailsRoot } = lazily(
  () => import("app/views/overview/policies/details/PolicyDetailsRoot"),
);
const { ExecutionDetailsRoot } = lazily(
  () => import("app/views/overview/policies/details/ExecutionDetailsRoot"),
);

// dashboards
const { CostDashboard } = lazily(
  () => import("app/views/dashboards/cost/CostDashboard"),
);
const { ComplianceDashboard } = lazily(
  () => import("app/views/dashboards/components"),
);
const { SectionDetails } = lazily(
  () => import("app/views/dashboards/details/SectionDetails"),
);
const { OpportunityDetails } = lazily(
  () => import("app/views/dashboards/cost/details/OpportunityDetails"),
);

// cloudops
const { ResourceExplorerRoot } = lazily(
  () => import("app/views/cloudops/resources/ResourceExplorerRoot"),
);
const { ResourceDetailsRoot } = lazily(
  () => import("app/views/cloudops/resources/details/ResourceDetailsRoot"),
);
const { ResourceMatchesRoot } = lazily(
  () => import("app/views/cloudops/resource-matches/ResourceMatchesRoot"),
);

// admin
const { AdminPoliciesRoot } = lazily(() => import("app/views/admin/policies"));
const { BindingDetailsRoot } = lazily(
  () => import("app/views/admin/bindings/details/BindingDetailsRoot"),
);
const { BindingsRoot } = lazily(
  () => import("app/views/admin/bindings/BindingsRoot"),
);
const { CreateBindingRoot } = lazily(
  () => import("app/views/admin/bindings/create/CreateBindingRoot"),
);
const { EditBindingRoot } = lazily(
  () => import("app/views/admin/bindings/edit/EditBindingRoot"),
);
const { RunDetailsRoot } = lazily(
  () => import("app/views/admin/bindings/components/RunDetailsRoot"),
);
const { AccountDetailsRoot } = lazily(
  () => import("app/views/admin/accounts/details/AccountDetailsRoot"),
);
const { AccountsRoot } = lazily(
  () => import("app/views/admin/accounts/AccountsRoot"),
);
const { AddAccountRoot } = lazily(
  () => import("app/views/admin/accounts/add/AddAccountRoot"),
);
const { EditAccountRoot } = lazily(
  () => import("app/views/admin/accounts/edit/EditAccountRoot"),
);
const { AccountGroupsRoot } = lazily(
  () => import("app/views/admin/account-groups/AccountGroupsRoot"),
);
const { AccountGroupDetailsRoot } = lazily(
  () =>
    import("app/views/admin/account-groups/details/AccountGroupDetailsRoot"),
);
const { AccountGroupCreateRoot } = lazily(
  () => import("app/views/admin/account-groups/create/AccountGroupCreateRoot"),
);
const { EditAccountGroupRoot } = lazily(
  () => import("app/views/admin/account-groups/edit/EditAccountGroupRoot"),
);
const { PolicyCollectionsRoot } = lazily(
  () => import("app/views/admin/policy-collections/PolicyCollectionsRoot"),
);
const { CreatePolicyCollectionRoot } = lazily(
  () =>
    import(
      "app/views/admin/policy-collections/create/CreatePolicyCollectionRoot"
    ),
);
const { PolicyCollectionDetails } = lazily(
  () =>
    import(
      "app/views/admin/policy-collections/details/PolicyCollectionDetails"
    ),
);
const { EditPolicyCollectionRoot } = lazily(
  () =>
    import("app/views/admin/policy-collections/edit/EditPolicyCollectionRoot"),
);

// notifications
const { IntegrationsRoot } = lazily(
  () => import("app/views/notifications/integrations/IntegrationsRoot"),
);
const { SlackRoot } = lazily(
  () => import("app/views/notifications/integrations/slack/SlackRoot"),
);
const { EmailRoot } = lazily(
  () => import("app/views/notifications/integrations/email/EmailRoot"),
);
const { JiraRoot } = lazily(
  () => import("app/views/notifications/integrations/jira/JiraRoot"),
);
const { ServiceNowRoot } = lazily(
  () =>
    import("app/views/notifications/integrations/service-now/ServiceNowRoot"),
);
const { NotificationsOverviewRoot } = lazily(
  () => import("app/views/notifications/overview"),
);
const { SettingsRoot } = lazily(
  () => import("app/views/notifications/settings/SettingsRoot"),
);
const { AddTemplateRoot } = lazily(
  () => import("app/views/notifications/settings/templates/AddTemplateRoot"),
);
const { EditTemplateRoot } = lazily(
  () => import("app/views/notifications/settings/templates/EditTemplateRoot"),
);

// settings
const { RepositoriesRoot } = lazily(
  () => import("app/views/settings/repositories/RepositoriesRoot"),
);
const { AddRepositoryRoot } = lazily(
  () => import("app/views/settings/repositories/add/AddRepositoryRoot"),
);
const { RepositoryDetailsRoot } = lazily(
  () => import("app/views/settings/repositories/details/RepositoryDetailsRoot"),
);
const { EditRepositoryRoot } = lazily(
  () => import("app/views/settings/repositories/edit/EditRepositoryRoot"),
);
const { UsersRoot } = lazily(
  () => import("app/views/settings/users/UsersRoot"),
);
const { UserDetailsRoot } = lazily(
  () => import("app/views/settings/users/user-list/details/UserDetailsRoot"),
);

// api
const { Sandbox } = lazily(() => import("app/views/sandbox/Sandbox"));

interface DashboardContentProps {
  CostComponent: ComponentType;
  ComplianceComponent: ComponentType;
}
function DashboardContent({
  CostComponent,
  ComplianceComponent,
}: DashboardContentProps) {
  const { key } = useParams();

  if (key?.includes("cost")) {
    return (
      <CostDashboardBarStateProvider>
        <CostComponent />
      </CostDashboardBarStateProvider>
    );
  }

  return <ComplianceComponent />;
}

interface Props {
  queryRef: Routes_accounts$key;
}

export function AppRoutes({ queryRef }: Props) {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  const [orgDetails, setOrgDetails] = useState<AWSOrganisationInput | null>(
    null,
  );

  const orgData = useMemo(() => ({ orgDetails, setOrgDetails }), [orgDetails]);

  const data = useFragment(
    graphql`
      fragment Routes_accounts on Query {
        accounts {
          pageInfo {
            total
          }
        }
        whoami {
          __typename
          config {
            isOnboardingSkipped
          }
        }
      }
    `,
    queryRef,
  );
  const config = useConfig();

  if (
    data.accounts.pageInfo.total === 0 &&
    !data.whoami.config?.isOnboardingSkipped
  ) {
    return (
      <Routes>
        <Route element={<Navigate to="/onboarding" />} path="/*" />
        <Route path="onboarding">
          <Route
            element={
              <OnboardingContext.Provider value={orgData}>
                <OnboardingWizardRoot />
              </OnboardingContext.Provider>
            }
            index
          />
        </Route>
      </Routes>
    );
  }

  const hasPermission = useHasRequiredPermission();
  const isAdmin = hasPermission(ADMIN_PERMISSIONS);

  return (
    <SentryRoutes>
      {/* /onboarding */}
      <Route path="onboarding">
        <Route
          element={
            <OnboardingContext.Provider value={orgData}>
              <OnboardingWizardRoot />
            </OnboardingContext.Provider>
          }
          index
        />
      </Route>

      {/* /overview */}
      <Route element={<Navigate to="/overview" />} index />
      <Route path="setup">
        <Route element={<Navigate to="/overview" />} index />
      </Route>
      <Route path="overview">
        <Route element={<Navigate to={"/dashboards/cloud"} />} index />
      </Route>

      {/* /activity */}
      <Route path="activity">
        <Route element={<Activity />} index />
      </Route>

      {/* /dashboards */}
      <Route path="dashboards">
        <Route element={<Navigate to={"/dashboards/cloud"} />} index />
        <Route path="cloud">
          <Route element={<Cloud />} index />
        </Route>
        <Route
          element={
            <DashboardContent
              ComplianceComponent={ComplianceDashboard}
              CostComponent={CostDashboard}
            />
          }
          path=":key"
        />
        <Route
          element={
            <DashboardContent
              ComplianceComponent={SectionDetails}
              CostComponent={OpportunityDetails}
            />
          }
          path=":key/details/:sectionKey"
        />
      </Route>

      {/* read-only (/bindings, /accounts, /account-groups, /policy-collections) */}
      <Route path="bindings">
        <Route
          element={<BindingDetailsRoot showActions={false} />}
          path=":bindingUUID"
        />
      </Route>
      <Route path="accounts">
        <Route
          element={<AccountDetailsRoot showActions={false} />}
          path=":accountKey"
        />
      </Route>
      <Route path="account-groups">
        <Route
          element={<AccountGroupDetailsRoot showActions={false} />}
          path=":accountGroupUUID"
        />
      </Route>
      <Route path="policy-collections">
        <Route
          element={<PolicyCollectionDetails showActions={false} />}
          path=":collectionId"
        />
      </Route>

      {/* /policies */}
      <Route path="policies">
        <Route
          element={
            <Suspense fallback={<TableSkeleton size="medium" topMargin={15} />}>
              <PoliciesRoot />
            </Suspense>
          }
          index
        />
        <Route path=":policyUUID">
          <Route element={<PolicyDetailsRoot />} index />
          <Route
            element={<PolicyDetailsRoot />}
            path="version/:versionNumber"
          />
        </Route>
        <Route
          element={<ExecutionDetailsRoot />}
          path=":policyUuid/execution/:executionId"
        />
      </Route>

      {/* /cloudops */}
      <Route path="cloudops">
        <Route
          element={<ResourceMatchesRoot />}
          path="resource-matches"
          index
        />
        <Route path="resources">
          <Route element={<ResourceExplorerRoot />} index />
          <Route element={<ResourceDetailsRoot />} path=":resourceKey" />
          <Route
            element={<ExecutionDetailsRoot />}
            path=":resourceKey/:executionId"
          />
        </Route>
      </Route>

      {/* /admin */}
      <Route path="admin">
        <Route element={<Navigate to="bindings" />} index />
        {/* /admin/accounts */}
        <Route path="accounts">
          <Route element={<AccountsRoot showActions={isAdmin} />} index />
          <Route
            element={
              <AdminRoute>
                <Suspense fallback={<DetailsSkeleton searchBar={true} />}>
                  <AddAccountRoot />
                </Suspense>
              </AdminRoute>
            }
            path="add"
          />
          <Route
            element={
              <Suspense fallback={<DetailsSkeleton searchBar={true} />}>
                <AccountDetailsRoot showActions={isAdmin} />
              </Suspense>
            }
            path="details/:accountProvider/:accountKey"
          />
          <Route
            element={
              <AdminRoute>
                <Suspense fallback={<DetailsSkeleton searchBar={true} />}>
                  <EditAccountRoot />
                </Suspense>
              </AdminRoute>
            }
            path="details/:accountProvider/:accountKey/edit"
          />
        </Route>

        {/* /admin/policy-collections */}
        <Route path="policy-collections">
          <Route
            element={<PolicyCollectionsRoot showActions={isAdmin} />}
            index
          />
          <Route
            element={
              <AdminRoute>
                <CreatePolicyCollectionRoot />
              </AdminRoute>
            }
            path="create"
          />
          <Route
            element={
              <Suspense fallback={<DetailsSkeleton />}>
                <PolicyCollectionDetails showActions={isAdmin} />
              </Suspense>
            }
            path=":collectionId"
          />
          <Route
            element={
              <AdminRoute>
                <EditPolicyCollectionRoot />
              </AdminRoute>
            }
            path=":collectionId/edit"
          />
        </Route>

        {/* /admin/account-groups */}
        <Route path="account-groups">
          <Route element={<AccountGroupsRoot showActions={isAdmin} />} index />
          <Route
            element={
              <AdminRoute>
                <AccountGroupCreateRoot />
              </AdminRoute>
            }
            path="create"
          />
          <Route
            element={
              <Suspense fallback={<DetailsSkeleton />}>
                <AccountGroupDetailsRoot showActions={isAdmin} />
              </Suspense>
            }
            path=":accountGroupUUID"
          />
          <Route
            element={
              <AdminRoute>
                <Suspense fallback={<DetailsSkeleton />}>
                  <EditAccountGroupRoot />
                </Suspense>
              </AdminRoute>
            }
            path=":accountGroupUUID/edit"
          />
        </Route>

        {/* /admin/bindings */}
        <Route path="bindings">
          <Route
            element={
              <Suspense
                fallback={<TableSkeleton size="large" topMargin={16} />}
              >
                <BindingsRoot showActions={isAdmin} />
              </Suspense>
            }
            index
          />
          <Route
            element={
              <AdminRoute>
                <Suspense
                  fallback={<TableSkeleton size="large" topMargin={16} />}
                >
                  <CreateBindingRoot />
                </Suspense>
              </AdminRoute>
            }
            path="create"
          />

          {/* /admin/bindings/:uuid */}
          <Route path=":bindingUUID">
            <Route
              element={
                <Suspense fallback={<DetailsSkeleton />}>
                  <BindingDetailsRoot showActions={isAdmin} />
                </Suspense>
              }
              index
            />
            <Route
              element={
                <AdminRoute>
                  <EditBindingRoot />
                </AdminRoute>
              }
              path="edit"
            />

            {/* /admin/bindings/:uuid/run */}
            <Route path="run">
              <Route
                element={
                  <Suspense fallback={<DetailsSkeleton />}>
                    <RunDetailsRoot />
                  </Suspense>
                }
                path=":runID"
              />
            </Route>
          </Route>
        </Route>

        {/* /admin/policies */}
        <Route path="policies">
          <Route element={<AdminPoliciesRoot showActions={isAdmin} />} index />
        </Route>
      </Route>

      {/* /notifications */}
      <Route path="notifications">
        <Route element={<NotificationsOverviewRoot />} path="overview" />

        {/* /notifications/settings */}
        <Route path="settings">
          <Route element={<SettingsRoot />} index />

          {/* /notifications/settings/templates */}
          <Route path="templates">
            <Route element={<AddTemplateRoot />} path="add" />
            <Route element={<EditTemplateRoot />} path=":name" />
          </Route>
        </Route>
      </Route>

      {/* /settings */}
      <Route path="settings">
        <Route element={<Navigate to="integrations" />} index />

        {/* /settings/integrations */}
        <Route path="integrations">
          <Route element={<IntegrationsRoot />} index />
          <Route element={<SlackRoot />} path="slack" />
          <Route element={<EmailRoot />} path="email" />
          <Route element={<JiraRoot />} path="jira" />
          <Route element={<ServiceNowRoot />} path="service-now" />
        </Route>

        {/* /settings/users */}
        <Route path="users">
          <Route element={<UsersRoot />} index />
          <Route
            element={
              <Suspense
                fallback={
                  <>
                    <RectangleSkeleton height="32px" />
                    <RectangleSkeleton height="125px" topMargin={4} />
                  </>
                }
              >
                <UserDetailsRoot />
              </Suspense>
            }
            path=":key"
          />
        </Route>

        {/* /settings/repositories */}
        <Route path="repositories">
          <Route element={<RepositoriesRoot />} index />
          <Route element={<AddRepositoryRoot />} path="add" />
          <Route
            element={
              <Suspense fallback={<DetailsSkeleton />}>
                <RepositoryDetailsRoot />
              </Suspense>
            }
            path=":repositoryUUID"
          />
          <Route
            element={
              <Suspense fallback={<DetailsSkeleton />}>
                <EditRepositoryRoot />
              </Suspense>
            }
            path=":repositoryURL/edit"
          />
        </Route>

        {/* /settings/admin */}
        {config?.dev_flags.includes("admin-settings") ? (
          <Route path="admin">
            <Route element={<AdminSettingsRoot />} index />
          </Route>
        ) : null}
      </Route>

      {/* /api */}
      <Route element={<Sandbox />} path="api" />

      <Route element={<NotFound />} path="*" />
    </SentryRoutes>
  );
}
